import React, { useCallback, useMemo, useRef, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { LazyImage } from './lazy-image'
import Img from 'gatsby-image'
import _chunk from 'lodash-es/chunk'

export default function ProductGrid({ projectsImages }) {
  const [isOpen, setOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState('')
  const projects = useRef([...projectsImages.edges].reverse())
  const projectsImagesIds = useRef(
    [...projects.current].reduce(
      (
        prev,
        {
          node: {
            childImageSharp: { id, fluid }
          }
        },
        currIndex,
        edges
      ) => ({
        ...prev,
        [id]: {
          fluid,
          prev:
            currIndex === 0
              ? edges[edges.length - 1]?.node.childImageSharp.id
              : edges[currIndex - 1]?.node.childImageSharp.id,
          next:
            currIndex === edges.length - 1
              ? edges[0].node.childImageSharp.id
              : edges[currIndex + 1]?.node.childImageSharp.id
        }
      }),
      {}
    )
  )

  function shuffleArray(array) {
    const newArr = [...array]
    for (var i = newArr.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1))
      var temp = newArr[i]
      newArr[i] = newArr[j]
      newArr[j] = temp
    }

    return newArr
  }

  const renderColumns = useCallback(
    (count) => {
      const columns = _chunk(
        projects.current,
        (projects.current.length + count - 1) / count
      ).map((values, columnIndex) => (
        <div className="flex-1" key={columnIndex}>
          {values.map(({ node: { childImageSharp: { fluid, id } } }) => (
            <LazyImage key={id}>
              <div
                className="cursor-pointer mx-1 md:mx-2 mb-2 md:mb-4"
                key={id}
                onClick={() => {
                  setOpen(true)
                  setPhotoIndex(id)
                }}
              >
                <Img fluid={fluid} />
              </div>
            </LazyImage>
          ))}
        </div>
      ))

      return columns
    },
    [projects]
  )

  const col2 = useMemo(() => renderColumns(2), [renderColumns])
  const col3 = useMemo(() => renderColumns(3), [renderColumns])
  const col5 = useMemo(() => renderColumns(5), [renderColumns])

  return (
    <React.Fragment>
      <div className={'flex mx-1 md:hidden'}>{col2}</div>
      <div className={'mx-2 hidden md:flex xl:hidden'}>{col3}</div>
      <div className={'mx-2 hidden xl:flex'}>{col5}</div>

      {isOpen && (
        <Lightbox
          imageTitle="Photo"
          mainSrc={projectsImagesIds.current[photoIndex].fluid.src}
          nextSrc={
            projectsImagesIds.current[
              projectsImagesIds.current[photoIndex].next
            ].fluid.src
          }
          prevSrc={
            projectsImagesIds.current[
              projectsImagesIds.current[photoIndex].prev
            ].fluid.src
          }
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex) => projectsImagesIds.current[photoIndex].next
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex) => projectsImagesIds.current[photoIndex].prev
            )
          }
        />
      )}
    </React.Fragment>
  )
}
