import React from 'react'
import SEO from '../components/SEO'
import PageTitle from '../components/PageTitle'
import ProductGrid from '../components/ProductGrid'
import { graphql } from 'gatsby'

const ProjectsPage = ({ data: { images } }) => (
  <>
    <SEO title="Matys carpentry - Professional and comprehensives services in the construction business." />
    <div className="mt-20 md:mt-32"></div>
    <PageTitle>Projects</PageTitle>
    <ProductGrid projectsImages={images} />
  </>
)

export const query = graphql`
  {
    images: allFile {
      edges {
        node {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
              presentationHeight
            }
          }
        }
      }
    }
  }
`

export default ProjectsPage
