import * as React from 'react'
import { useInView } from 'react-intersection-observer'

export const LazyImage = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '50px 0px'
    
  })

  return (
    <div ref={ref} data-inview={inView}>
      {inView ? children : <div style={{ width: 30, height: 300 }}></div>}
    </div>
  )
}
